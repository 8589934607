import * as client_hooks from '../../../src/hooks.client.ts';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18')
];

export const server_loads = [];

export const dictionary = {
		"/": [~2],
		"/cart/[uuid]": [~5],
		"/cart/[uuid]/scan": [~6],
		"/login/[uuid]": [~7],
		"/logout": [~8],
		"/order/[permalink]/[puid]": [~9],
		"/qr/[uuid]": [~10],
		"/qr/[uuid]/change_email": [11],
		"/qr/[uuid]/change_name": [12],
		"/qr/[uuid]/change_phone": [13],
		"/qr/[uuid]/more": [~14],
		"/register/[username]": [~15],
		"/register/[username]/completed": [~16],
		"/request_visit/confirm_required/[uuid]": [~18],
		"/request_visit/[uuid]": [~17],
		"/[permalink]/login": [~3],
		"/[permalink]/logout": [~4]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';